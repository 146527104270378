//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  components: {
    Header: () => import("@/components/Header"),
    ProgressGroup: () => import("./progressGroup"),
    processRule: () => import("./processRule"),
    processMark: () => import("./processMark"),
    WindowAlias: () => import("./windowalias"),
    ActionRule: () => import("./action-rule"),
    SensitiveWords: () => import("./sensitive-words"),
    ShieldingSoftware: () => import("./shielding-software"),
    UrlSet: () => import("./url-set"),
  },
  props: {
    //规则版本信息
    selRow: {
      type: Object,
      default: null,
    },
    teamValue: {
      type: Number,
      default: 0,
    },
    infoData: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    tabType: 1,
    tabList: [
      {
        value: 6,
        label: "敏感词",
      },
      {
        value: 7,
        label: "屏蔽的软件",
      },
      {
        value: 1,
        label: "部门设置",
      },
      // {
      //   value: 5,
      //   label: "行为规则设置",
      // },
      //
      // {
      //   value: 2,
      //   label: "进程规则设置",
      // },
      // {
      //   value: 8,
      //   label: "网络请求分类",
      // },
      // {
      //   value: 3,
      //   label: "进程标记",
      // },
      // {
      //   value: 4,
      //   label: "窗口别名",
      // },
    ],
  }),
  computed: {
    tabListWithFunctions() {
      return this.tabList.filter((m) => {
        if (m.value == 6) {
          return this.checkFunction("敏感词记录");
        } else if (m.value == 7) {
          return this.checkFunction("行为管控");
        }
        return true;
      });
    },
  },
  methods: {
    checkFunction(func) {
      if (!this.infoData || !this.infoData.Teamdata.Functions) {
        return false;
      }
      return this.infoData.Teamdata.Functions.indexOf(func) != -1;
    },
    tabChange(value) {
      this.tabType = value;
    },
  },
};
